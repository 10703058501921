import { useTheme } from '@emotion/react'
import { Box, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { FormData } from '../..'
import { IconPercentage, IconPlus, IconTrash } from '@tabler/icons-react'
import { Select } from '~/components/Form/Select'
import { brazilStates } from '~/constants'
import { useCallback } from 'react'
import { Input } from '~/components/Form/Input'
import { confirmDialog } from '~/components'
import { Option } from '~/utils/options'
import { DealershipListQuery, PartnerModel } from '~/graphql/types'

type DiscountExceptionTableProps = {
  dealerships: DealershipListQuery['dealershipList']['data']
  selectedPartner: PartnerModel | null
}

export const DiscountExceptionTable: React.FC<DiscountExceptionTableProps> = ({ dealerships, selectedPartner }) => {
  const theme = useTheme()
  const formMethods = useFormContext<FormData>()
  const { name, discountsException } = useWatch({ control: formMethods.control })
  const { fields, append, remove } = useFieldArray({ control: formMethods.control, name: 'discountsException' })
  const onAddDealership = useCallback(() => {
    append({
      dealershipId: '',
      uf: '',
    })
  }, [])
  
  const onRemoveDealership = useCallback(async (index: number) => {
    if (discountsException) {
      const dealershipName = dealerships.find(item => item._id === discountsException?.[index].dealershipId)?.name
      const isConfirmed = await confirmDialog({
        title: 'Remover concessionária com exceção de benefício',
        type: 'warning',
        proccedText: 'Sim, desejo remover',
        content: <Box>
          {dealershipName ? (
            <>
              <p>Tem certeza de que deseja remover <b>{`${dealershipName}`}</b> da lista de concessionárias com exceção de benefício para o parceiro <b>{name}</b>?</p>
            </>
          ) 
            : (
              <>
                <p>Tem certeza de que deseja remover esses campos da lista de concessionárias com exceção de benefício para o parceiro <b>{name}</b>?</p>
              </>
            )}
        </Box>
      })

      if (isConfirmed) {
        remove(index)
      }
    }
  }, [discountsException, name, dealerships])

  return (
    <div>
      <Typography color={theme.palette.grey[800]} sx={{ fontWeight: 500, fontSize: '16px', padding: '.2rem 0 .4rem 0' }}>Concessionárias com exceção de benefício oferecido</Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow><TableCell colSpan={10} /></TableRow>
            <TableRow>
              <TableCell sx={{ minWidth: '200px', color: theme.palette.grey[600] }}>UF</TableCell>
              <TableCell sx={{ minWidth: '260px', color: theme.palette.grey[600] }}>Concessionária</TableCell>
              <TableCell sx={{ color: theme.palette.grey[600] }}>Benefício mínimo</TableCell>
              <TableCell sx={{ color: theme.palette.grey[600] }}>Benefício máximo</TableCell>
              <TableCell sx={{ color: theme.palette.grey[600] }}>Benefício sugerido</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, i) => {
              const fieldWatch = discountsException ? discountsException[i] : undefined

              const isEditDealership = Boolean(selectedPartner?.discountsException.find(item => item.dealershipId === fieldWatch?.dealershipId))

              const dealershipByField = dealerships.find(item => item._id === fieldWatch?.dealershipId)
         
              const dealershipEditOptions: Option[] = dealerships
                .map(item => ({ value: item._id, label: item.name || '' })) 
              || []

              const dealershipOptions: Option[] = dealerships
                .filter(item => item.locationUf === fieldWatch?.uf)
                .map(item => ({ value: item._id, label: item.name || '' })) 
              || []

              return (
                <TableRow
                  key={field.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Select disabled={Boolean(isEditDealership)} label='UF' options={brazilStates} name={`discountsException.${i}.uf`} />
                    {Boolean(fieldWatch?.dealershipId) && (
                      <Typography sx={{ fontSize: '10px', color: theme.palette.grey[400], paddingTop: '.4rem', visibility: 'hidden' }}>
                        -
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Select 
                        disabled={Boolean(isEditDealership) || Boolean(!fieldWatch?.uf)} 
                        label='Concessionária' 
                        options={isEditDealership ? dealershipEditOptions : dealershipOptions} name={`discountsException.${i}.dealershipId`} 
                      />
                      {Boolean(fieldWatch?.dealershipId) && (
                        <Typography sx={{ fontSize: '10px', color: theme.palette.grey[400], paddingTop: '.4rem' }}>
                          <b>Ref. Padrão:</b> PF: {dealershipByField?.maxDiscountPercentagePf}% | PJ: {dealershipByField?.maxDiscountPercentagePj}%
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <Box>

                          <Input 
                            disabled={Boolean(!fieldWatch?.dealershipId)} 
                            mask='percentage' 
                            icons={{ end: { element: <IconPercentage /> } }} 
                            label='PF' 
                            name={`discountsException.${i}.customerPFDiscountMin`} 
                          />
                          {Boolean(fieldWatch?.dealershipId) && (
                            <Typography sx={{ fontSize: '10px', color: theme.palette.grey[400], paddingTop: '.4rem' }}>
                              <b>Ref.:</b> PF: {dealershipByField?.partnerBenefitToCustomer.customerPFDiscountMin}%
                            </Typography>
                          )}
                        </Box>

                        <Box>

                          <Input 
                            disabled={Boolean(!fieldWatch?.dealershipId)} 
                            mask='percentage' 
                            icons={{ end: { element: <IconPercentage /> } }} 
                            label='PJ' 
                            name={`discountsException.${i}.customerPJDiscountMin`} 
                          />
                          {Boolean(fieldWatch?.dealershipId) && (
                            <Typography sx={{ fontSize: '10px', color: theme.palette.grey[400], paddingTop: '.4rem' }}>
                            PJ: {dealershipByField?.partnerBenefitToCustomer.customerPJDiscountMin}%
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>

                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <Box>

                          <Input 
                            disabled={Boolean(!fieldWatch?.dealershipId)} 
                            mask='percentage' 
                            icons={{ end: { element: <IconPercentage /> } }} 
                            label='PF' 
                            name={`discountsException.${i}.customerPFDiscountMax`} 
                          />
                          {Boolean(fieldWatch?.dealershipId) && (
                            <Typography sx={{ fontSize: '10px', color: theme.palette.grey[400], paddingTop: '.4rem' }}>
                              <b>Ref.:</b> PF: {dealershipByField?.partnerBenefitToCustomer.customerPFDiscountMax}%
                            </Typography>
                          )}
                        </Box>
                        <Box>

                          <Input 
                            disabled={Boolean(!fieldWatch?.dealershipId)} 
                            mask='percentage' 
                            icons={{ end: { element: <IconPercentage /> } }} 
                            label='PJ' 
                            name={`discountsException.${i}.customerPJDiscountMax`} 
                          />
                          {Boolean(fieldWatch?.dealershipId) && (
                            <Typography sx={{ fontSize: '10px', color: theme.palette.grey[400], paddingTop: '.4rem' }}>
                              PJ: {dealershipByField?.partnerBenefitToCustomer.customerPJDiscountMax}%
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>

                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <Box>

                          <Input 
                            disabled={Boolean(!fieldWatch?.dealershipId)} 
                            mask='percentage' 
                            icons={{ end: { element: <IconPercentage /> } }} 
                            label='PF' 
                            name={`discountsException.${i}.customerPFDiscountSuggested`} 
                          />
                          {Boolean(fieldWatch?.dealershipId) && (
                            <Typography sx={{ fontSize: '10px', color: theme.palette.grey[400], paddingTop: '.4rem' }}>
                              <b>Ref.:</b> PF: {dealershipByField?.partnerBenefitToCustomer.customerPFDiscountSuggested}%
                            </Typography>
                          )}
                        </Box>
                        <Box>

                          <Input 
                            disabled={Boolean(!fieldWatch?.dealershipId)} 
                            mask='percentage' 
                            icons={{ end: { element: <IconPercentage /> } }} 
                            label='PJ' 
                            name={`discountsException.${i}.customerPJDiscountSuggested`} 
                          />
                          {Boolean(fieldWatch?.dealershipId) && (
                            <Typography sx={{ fontSize: '10px', color: theme.palette.grey[400], paddingTop: '.4rem' }}>
                              PJ: {dealershipByField?.partnerBenefitToCustomer.customerPJDiscountSuggested}%
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => onRemoveDealership(i)}>
                      <IconTrash color={theme.palette.error.main} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell colSpan={10}>
                <Button startIcon={<IconPlus />} onClick={() => onAddDealership()} color='secondary'>Adicionar distribuidora</Button>
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}></TableRow>
           
          </TableBody>
        </Table>
      </TableContainer>

    </div>
  )
}
