import { Box, IconButton, InputAdornment, TextField, useMediaQuery } from '@mui/material'
import { IconArrowRight, IconSearch } from '@tabler/icons-react'
import { Card, ContentTitle, Status, TableApiV2, TableHeaderApi } from '~/components'
import { FilterPartnerDrawer } from './components'
import { useNavigate } from 'react-router-dom'
import { SearchQueysData, useDebounce, usePermission, useQueryString, useToggle } from '~/hooks'
import { useTheme } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import { displayCpfCnpj, displayPhone, formatDate } from '~/utils'
import { usePartnerListQuery } from '~/graphql/types'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'

export const PartnerList: React.FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const canEditPartner = usePermission(['partner.updated', 'partner.read'])
  const { isTrue: drawerIsOpen, toggle: toggleDrawer, setIsTrue: setDrawerIsOpen } = useToggle(false)

  const queryKeys: SearchQueysData[] = [
    { key: 'status', defaultValue: [], parseValue: true },
    { key: 'pendencies', defaultValue: [], parseValue: true },
    { key: 'origin', defaultValue: [], parseValue: true },
    { key: 'funnel', defaultValue: [], parseValue: true },
    { key: 'name', defaultValue: '' },
    { key: 'begin', defaultValue: '' },
    { key: 'end', defaultValue: '' },
  ]
  const { searchQuerys, setSearchParams, page, size } = useQueryString()

  const queryValues = searchQuerys(queryKeys)

  const isLowerlg = useMediaQuery(theme.breakpoints.down('xl'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [filterName, setFilterName] = useState(queryValues.name)

  const debouncedFilterName = useDebounce(filterName, 1000)

  useEffect(() => {
    setSearchParams(state => {
      if (debouncedFilterName) {
        state.set('name', debouncedFilterName)
        state.set('page', '0')
      } else {
        state.delete('name')
      }
      return state
    })
  }, [debouncedFilterName])

  const { data, loading } = usePartnerListQuery({
    variables: {
      params: {
        pageSize: size,
        pageNumber: page,
        sort: {
          field: 'createdAt',
          order: -1
        },
        // ...queryValues?.name?.length > 0 ? { name: ifIsDocumentReturnFormated(queryValues.name) } : {},
      }
    },
    onCompleted(result) {
      setSearchParams(state => {
        state.set('items', String(result.partnerList.meta.documentCounts))
        return state
      })
    },
    fetchPolicy: 'no-cache'
  })

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Parceiro',
        accessor: 'name',
        // Cell: (props) => <>props.value</>,
      },
      {
        Header: 'Documento',
        accessor: 'document',
        Cell: (props) => <>{displayCpfCnpj(props.value)}</>,
        disableSortBy: true,
        filter: 'includes'
      },
      {
        Header: 'Telefone',
        accessor: 'phone',
        Cell: (props) => <>{displayPhone(props.value)}</>
      },
      {
        Header: 'Criado em',
        accessor: 'createdAt',
        Cell: (props) => <>{formatDate(props.value)}</>
      },
      {
        Header: 'Atualizado em',
        accessor: 'updatedAt',
        Cell: (props) => <>{formatDate(props.value)}</>
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Cell: (props) => (
          <>
            {props.value === 'active' ? <Status background={theme.palette.success.light} text='Ativo' color={theme.palette.success.main} /> : <Status text='Inativo' color={theme.palette.grey[400]} />}
          </>
        )
      },
      {
        Header: 'Ações',
        accessor: '_id',
        disableSortBy: true,
        Cell: (props) => (
          <IconButton disabled={!canEditPartner} onClick={() => navigate(`/app/partners/update/${props.value}`)}>
            <IconArrowRight />
          </IconButton>
        )
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => data?.partnerList?.data || [], [data])

  const defaultColumnHiddens = isLowerlg ? ['createdAt'].concat(isLowerMd ? [''] : []).concat(isLowerSm ? [''] : '') : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    defaultColumn: {
      Filter: <></>
    },
    manualPagination: true,
    initialState: {
      hiddenColumns: [...defaultColumnHiddens, '']
    },
  },
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
  )

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem 1rem 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title='Parceiros cadastrados'
          description='Visualize e gerencie os parceiros cadastrados'
          breadcrumbLinks={{ currentLink: 'Parceiros cadastrados', links: [{ href: '/app/partners', label: 'Parceiros' }] }}
        />

        <TableHeaderApi
          startElement={
            (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', width: '100%', flexFlow: isLowerSm ? 'column-reverse' : 'row' }}>
                <TextField
                  fullWidth
                  sx={{ maxWidth: '400px' }}
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  placeholder='Buscar nome, documento, telefone...'
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position='end'>
                          <IconSearch />
                        </InputAdornment>
                      </>
                    )
                  }}
                />
                {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <FilterButton onCustomClear={() => setFilterName('')} filtersToClear={queryKeys} toggleDrawer={toggleDrawer} filterCounter={searchParams.size - paginationQuerySize} />
                </Box> */}

                <FilterPartnerDrawer queryKeys={queryKeys} drawerIsOpen={drawerIsOpen} setDrawerIsOpen={setDrawerIsOpen} toggleDrawer={toggleDrawer} />
              </Box>
            )
          }
        />
        <TableApiV2
          isLoading={loading}
          tableMethods={tableMethods}
          columns={columns}
        />
      </Card>
    </div>
  )
}
